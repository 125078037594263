import React, { useState, useRef, useEffect } from "react"
import { Popover } from "@headlessui/react"
import SlideOver from "../components/SlideOver"

import "../assets/css/menu.css"
import "../assets/css/global.css"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Films from "../components/Films"
import Advertising from "../components/Advertising"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"

import burger_pos from "../images/burger-neg.svg"
import logo_films from "../images/logo-films.svg"
import comercial from "../images/commercial.mp4"
import drama from "../images/drama.mp4"

import introSafari from "../../videos/hevc-safari.mp4"
import introChrome from "../../videos/vp9-chrome.webm"

const IndexPage = () => {
  const [introVideoEnded, setIntroVideoEnd] = useState(false)

  const [advertising, setAdvertising] = useState(false)
  const [films, setFilms] = useState(false)

  const [hoverLeft, setHoverLeft] = useState(false)
  const [hoverRight, setHoverRight] = useState(false)

  const handleHoverLeft = isHover => {
    setHoverLeft(isHover)
  }
  const handleHoverRight = isHover => {
    setHoverRight(isHover)
  }

  const introRef = useRef(null)

  const filmsRef = useRef(null)
  const advRef = useRef(null)

  useEffect(() => {
    if (!introVideoEnded) {
      introRef.current.currentTime = 0
      introRef.current.play()
    }
  }, [introVideoEnded])

  const [open, setOpen] = useState(false)

  return (
    
    <Layout>
{!films && !advertising && (
    <Popover
      className="transparent"
      style={{ zIndex: 20, position: 'absolute', width: '100%' }}
    >
      <div className="mx-auto">
        <div className="flex items-center lg:justify-between border-gray-100 py-4">
          <div className="flex lg:justify-end lg:w-0 lg:flex-1 hamburger pr-10">
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className={`custom-mobile-hamburguer inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <img
                className="block h-8 w-8"
                src={ burger_pos }
                style={{ zIndex: 20, position: 'relative'}}
                alt=""
              ></img>
            </button>
          </div>
        </div>
      </div>
      <SlideOver open={open} setOpen={setOpen} />
    </Popover>
)}

      <section
        className={`intro-section ${
          !introVideoEnded ? "bg-black-intro" : "intro-transition"
        }`}
      >
        <video
          ref={introRef}
          playsInline
          muted
          autoPlay
          className={`intro-section`}
          onEnded={() => {
            setIntroVideoEnd(true)
          }}

        >
          <source src={introSafari} type='video/mp4; codecs="hvc1"' />
          <source src={introChrome} type="video/webm" />
        </video>
      </section>
      <div
        className={`super-container-1 ${
          (films || advertising) && "hideSuperContainer"
        }`}

      >
        {!films && !advertising && (
          <>
            <div className="white-line-filmstv" />
            <a
              className={`absolute z-10 tracking-widest text-white ref-text film ${
                (films || advertising) && "filmsAnimation"
              }`}
              onClick={() => setFilms(true)}
              onMouseEnter={e => {
                filmsRef.current.play()
                handleHoverLeft(true)
              }}
              onMouseLeave={e => {
                filmsRef.current.pause()
                handleHoverLeft(false)
              }}
            >
              FILM & TV
            </a>
          </>
        )}

        <video
          ref={filmsRef}
          playsInline
          muted
          autoPlay
          loop
          preload
          src={drama}
          type="video/mp4"
          className={`imageContainer-1 video-resp-1 absolute cut-image-1 reverse ${
            hoverLeft && !(films || advertising) ? "animationShowLeft" : ""
          } ${
            !hoverLeft && !(films || advertising)
              ? "animationShowLeftReverse"
              : ""
          } ${hoverRight && !hoverLeft ? "getToBackground" : ""} ${
            (films || advertising) && "animation1"
          }`}
          width="100%"
          height="100%"
          onMouseEnter={e => {
            e.target.play()
            handleHoverLeft(true)
          }}
          onMouseLeave={e => {
            e.target.pause()
            handleHoverLeft(false)
          }}
        >
          Your browser does not support the video tag.
        </video>

        {introVideoEnded && (
          <img
            className={`absolute z-10 logo ${
              (films || advertising) && "initLogoAnimation"
            } ${hoverLeft && !(films || advertising) ? "onRight" : ""}  ${
              hoverRight && !(films || advertising) ? "onLeft" : ""
            }`}
            src={logo_films}
            alt="logo"
          />
        )}

        <video
          ref={advRef}
          playsInline
          muted
          autoPlay
          loop
          preload
          src={comercial}
          type="video/mp4"
          className={`imageContainer-2 video-resp-2 absolute cut-image-2 ${
            hoverRight && !(films || advertising) ? "animationShowRight" : ""
          } ${
            !hoverRight && !(films || advertising)
              ? "animationShowRightReverse"
              : ""
          } ${hoverLeft && !hoverRight ? "getToBackground" : ""} ${
            (films || advertising) && "animation2"
          }`}
          width="100%"
          height="100%"
          onMouseEnter={e => {
            e.target.play()
            handleHoverRight(true)
          }}
          onMouseLeave={e => {
            e.target.pause()
            handleHoverRight(false)
          }}
        >
          Your browser does not support the video tag.
        </video>

        {!films && !advertising && (
          <>
            <div className="white-line-ads" />
            <a
              className={`absolute z-10 tracking-widest text-white ref-text adds ${
                (films || advertising) && "advertisingAnimation"
              }`}
              onClick={() => setAdvertising(true)}
              onMouseEnter={e => {
                advRef.current.play()
                handleHoverRight(true)
              }}
              onMouseLeave={e => {
                advRef.current.pause()
                handleHoverRight(false)
              }}
            >
              ADVERTISING
            </a>
          </>
        )}
      </div>

      {(films || advertising) && (
        <Navbar
          title={films ? `FILM & TV` : "Advertising"}
          dark={films}
          setAdvertising={setAdvertising}
          setFilms={setFilms}
          setIntroVideoEnd={setIntroVideoEnd}
        />
      )}

      {films && <Films />}
      {advertising && <Advertising />}

      {(films || advertising) && (
        <>
          <ContactForm dark={films} />
          <Footer dark={films} />
        </>
      )}
    </Layout>
  )
}

export default IndexPage

export const Head = () => <>
  <title>AG Films</title>
  <meta name="description" content="Established in 1992, AG Films is your premier choice for film production in Portugal. From TV commercials, movies and stills we bring iconic brands and stories to life against the backdrop of Portugal's stunning locations. Explore our comprehensive film services and discover available filming incentives" />
</>
